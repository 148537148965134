.navbar-toggle {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
  cursor: pointer;

  .toggle-icon {
    --width-icon: 25px;
    margin-right: 10px;

    .toggle-line {
      position: relative;
      height: 2px;
      background-color: $heading-color;
      width: 25px;
      transition: 0.5s;


      &:not(:first-child) {
        margin-top: 5px;
      }

      &:nth-of-type(1),
      &:nth-of-type(3) {
        transform: scaleX(0.5);
      }

      &:nth-of-type(2) {
        width: var(--width-icon);
      }

      &:nth-of-type(1) {
        transform-origin: left;
      }

      &:nth-of-type(3) {
        transform-origin: right;
      }


    }


  }

  .toggle-text {
    position: relative;
    --animate-trans: translateY(5px) rotateX(75deg);


    .text-open,
    .text-close {
      position: absolute;
      top: 0;
      left: 0;
      white-space: nowrap;
      display: flex;

      .char {
        opacity: 0;
        visibility: hidden;
        transform: var(--animate-trans);

      }
    }

    .text-open,
    .text-close,
    .text-menu {
      overflow: hidden;
      will-change: transform;

      .char {
        will-change: transform;
        transition-property: opacity, visibility, transform;
        transition-duration: 0.5s;
        transition-timing-function: $easeInOutBack;
        @include transition-delay;
      }
    }
  }

  &:hover {
    .toggle-line {
      --trans-icon: scale(0.2, 1.7);

      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3) {
        transform: var(--trans-icon);
        border-radius: 3px;
      }

      &:nth-of-type(1) {
        transform: var(--trans-icon) translateY(4px);
      }

      &:nth-of-type(3) {
        transform: var(--trans-icon) translateY(-4px);
      }
    }

    .text-menu .char {
      opacity: 0;
      visibility: hidden;
      transform: var(--animate-trans);
    }

    &:not(.open) .text-open,
    &.open .text-close {
      .char {
        opacity: 1;
        visibility: visible;
        transform: none;
      }
    }
  }
}