@import "../assets/sass/helper";

.cursor {
  @include media_991(min) {
    position: fixed;
    width: 30px;
    height: 30px;
    pointer-events: none;
    background-image: url('../assets/cursor.png');
    background-size: cover;
    transform-origin: left top;
    z-index: 999999;
    transform: translate(-50%, -50%);
  }

  @include media_991 {
    display: none;
  }
}